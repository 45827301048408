import styled from "styled-components";

const QuestionWrapper = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

const AnswerWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

const Next = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px
`;

const Back = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px
`;

const Error = styled.p`
  color: red;
  height: 20px;
`;

export default {
  QuestionWrapper,
  AnswerWrapper,
  Next,
  Error,
  Back,
}
