import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Select from "components/select";
import { SkillQuestionSelect } from "components/skillQuestion";
import { CompanyValuesSelect } from "components/companyValuesQuestion";
import Button from "components/button";
import Card from "components/card";
import Stepper from "components/stepper";
import LikertScaleQuestion from "components/likertScaleQuestion";

import { getLangMap } from "intl/index";

import styles from "components/wizard/styles";
import { getLangSettings } from "utils/language";

// TODO: move to data dir along with files from question form

const selectTypes = {
  MULTI: "multiSelect",
  SINGLE: "singleSelect",
  SINGLE_WITH_OPTION: "singleSelectWithOption",
  COMPANY_VALUES: "companyValues",
  LIKERT_SCALE: "likertScale",
};

const Wizard = ({
  options,
  questions,
  activeStep,
  onNextClick,
  onBackClick,
  settings,
}) => {
  const [answers, setAnswers] = useState([]);
  const [likertChoice, setLikertChoice] = useState("");
  const [error, setError] = useState("");
  const [skillError, setSkillError] = useState("");
  const isLastStep = questions.length - 1 === activeStep;
  const isFirstStep = activeStep === 0;
  const LANG_MAP = getLangMap(settings.language ?? getLangSettings());
  const singleWithOptionRef = useRef();
  const companyValuesRef = useRef();

  const handleChange = (choices) => {
    if (choices && choices.length === 20) {
      setError(LANG_MAP.MAX_SELECT_ERROR + " 20");
      return;
    }
    setAnswers(choices || []);
    setError("");
  };

  const handleLikertChange = (e) => {
    setLikertChoice(e.target.value);
  };

  const handleSubmit = () => {
    const questionType = questions[activeStep].type;

    if (questionType === selectTypes.SINGLE_WITH_OPTION) {
      const values = singleWithOptionRef?.current?.getState();
      const allSkills = values.map((value) => value.skill);
      if (allSkills.includes("")) {
        setSkillError(LANG_MAP.EMPTY_ERROR);
        return;
      }
      onNextClick(values);
      setAnswers([]);
      setError("");
      setSkillError("");
      return;
    }

    if (questionType === selectTypes.COMPANY_VALUES) {
      const values = companyValuesRef?.current?.getState();
      onNextClick(values);
      setAnswers([]);
      setError("");
      return;
    }

    if (questionType === selectTypes.LIKERT_SCALE) {
      // Uncomment this line and comment or delete the line below it to have a more detailed answer overview
      // onNextClick({ question: questions[activeStep].title, likertChoice});
      if (!likertChoice) {
        setError(LANG_MAP.EMPTY_ERROR);
      } else {
        onNextClick(likertChoice);
        setLikertChoice("");
        setAnswers([]);
        setError("");
      }

      return;
    }

    if (answers.length === 0) {
      setError(LANG_MAP.EMPTY_ERROR);
      return;
    }
    onNextClick(answers);
    setAnswers([]);
    setError("");
  };

  const handleBack = () => {
    onBackClick();
    setAnswers([]);
    setError("");
  };

  return (
    <Card>
      <styles.QuestionWrapper>
        <h3>{questions[activeStep].title}</h3>
        {activeStep + 1}. &nbsp; {questions[activeStep].name}
      </styles.QuestionWrapper>
      {/* <styles.AnswerWrapper>{LANG_MAP.CHOICES_TITLE}</styles.AnswerWrapper> */}
      {
        {
          [selectTypes.LIKERT_SCALE]: (
            <LikertScaleQuestion
              options={options}
              likertChoice={likertChoice}
              likertOptions={[
                "Strongly disagree",
                "Disagree",
                "Neutral",
                "Agree",
                "Strongy agree",
              ]}
              onChange={handleLikertChange}
            />
          ),
          [selectTypes.MULTI]: (
            <Select
              options={options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              onChange={handleChange}
              value={answers}
              isMulti
              placeholder={LANG_MAP.SELECT_INPUT}
            />
          ),
          [selectTypes.SINGLE]: (
            <Select
              options={options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              onChange={handleChange}
              value={answers}
              placeholder={LANG_MAP.SELECT_INPUT}
              isMulti={false}
            />
          ),
          [selectTypes.SINGLE_WITH_OPTION]: (
            <SkillQuestionSelect
              options={options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              placeholder={LANG_MAP.SELECT_INPUT}
              componentRef={singleWithOptionRef}
              langMap={LANG_MAP}
              error={skillError}
              onSkillChange={() => setSkillError("")}
            />
          ),
          [selectTypes.COMPANY_VALUES]: (
            <CompanyValuesSelect
              options={options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              placeholder={LANG_MAP.SELECT_INPUT}
              componentRef={companyValuesRef}
              langMap={LANG_MAP}
            />
          ),
        }[questions[activeStep].type]
      }

      <styles.Error>{error}</styles.Error>
      <Stepper activeStep={activeStep} steps={questions.length} />
      <styles.Back>
        <Button
          isDisabled={isFirstStep}
          onClick={handleBack}
          title={LANG_MAP.BACK_BUTTON}
          type={!isLastStep ? "secondary" : "primary"}
        />
      </styles.Back>

      <styles.Next>
        <Button
          type={isLastStep ? "secondary" : "primary"}
          onClick={handleSubmit}
          title={isLastStep ? LANG_MAP.FINISH_BUTTON : LANG_MAP.NEXT_BUTTON}
        />
      </styles.Next>
    </Card>
  );
};

Wizard.propTypes = {
  options: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

export default Wizard;
