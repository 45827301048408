import React from "react";
import styled from "styled-components";

import Layout from "layout";
import Card from "components/card";
import { getLangSettings } from "utils/language";
import { getLangMap } from "intl/index";

const Wrapper = styled.div`
  text-align: center;
`;

const UserAlreadyAnswered = () => {
  const lang = getLangSettings();
  const LANG_MAP = getLangMap(lang);

  return (
    <Layout withoutHeader={true}>
    <Card>
      <Wrapper>
        <h3>{LANG_MAP.FORM_ALREADY_ANSWERED}</h3>
      </Wrapper>
    </Card>
  </Layout>
  )
}

export default UserAlreadyAnswered;
