import React, { useState, useImperativeHandle } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import {  Grid, Button, TextField } from "@material-ui/core";
import MenuList from "components/menuList";

export const SkillQuestionSelect = ({
  options,
  placeholder,
  componentRef,
  langMap,
  error,
  onSkillChange
}) => {
  const [userAnswers, setUserAnswers] = useState([
    {
      user: options[0],
      skill: "",
    },
  ]);

  const handleChangeSelect = (values, index) => {
    const updatedSelect = {
      ...userAnswers[index],
      user: values,
    };
    let newState = [...userAnswers];
    newState[index] = updatedSelect;
    setUserAnswers(newState);
  };

  const handleChangeSkillLevel = (value, index) => {
    const updatedSelect = {
      ...userAnswers[index],
      skill: value,
    };
    let newState = [...userAnswers];
    newState[index] = updatedSelect;
    setUserAnswers(newState);
    onSkillChange();
  };

  useImperativeHandle(componentRef, () => ({
    getState: () => userAnswers,


  }));

  return (
    <>
      {userAnswers.map((item, idx) => (
        <Grid container justify="space-between" key={idx} style={{marginBottom: '12px'}}>
          <Grid item xs={6}>
            <Select
              components={{ MenuList }}
              placeholder={placeholder}
              value={item.user}
              options={options}
              onChange={(values) => handleChangeSelect(values, idx)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                },
              })}
              styles={{
                control: (base) => ({
                  ...base,
                  fontSize: "14px",
                }),
                option: (base) => ({
                  ...base,
                  fontSize: "14px",
                }),
              }}
            />
          </Grid>
          <TextField
            placeholder={langMap.SKILL_PLACEHOLDER}
            value={item.skill}
            onChange={(e) => handleChangeSkillLevel(e.target.value, idx)}
            error={!!error && userAnswers[idx].skill === ""}
            helperText={userAnswers[idx].skill === "" && error}
            
          />
          {idx === 0 ? (
            <div style={{ width: "64px" }} />
          ) : (
            <Button color="secondary" variant="outlined" size="small" onClick={ () => {
              setUserAnswers(userAnswers.filter((item, index) => index !== idx))
            }}>
              -
            </Button>
          )}
         
        </Grid>
      ))}
      <br />
      <Button
        color="primary"
        variant="contained"
        disabled={userAnswers.length === options.length}
        onClick={() =>
          setUserAnswers((answers) => [
            ...answers,
            { user: options[answers.length], skill: "" },
          ])
        }
      >
        +
      </Button>
    </>
  );
};

SkillQuestionSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  componentRef: PropTypes.any.isRequired,
  langMap: PropTypes.any.isRequired,
  error: PropTypes.string.isRequired,
  onSkillChange: PropTypes.func.isRequired,
};
