import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import Layout from "layout";
import Button from "components/button";
import Card from "components/card";
import { getLangMap } from "intl/index";
import { getLangSettings } from "utils/language";

const Center = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`
  line-height: 1.6;
  text-align: justify;
  text-justify: inter-word;
`;

const Welcome = ({
  onNextClick,
  welcomePageString,
  welcomePageString2,
  settings,
}) => {
  const [openSecondPage, setSecondPage] = useState(false);
  const LANG_MAP = getLangMap(settings.language ?? getLangSettings());

  const handleClick = () => {
    if (openSecondPage) {
      onNextClick();
    } else {
      setSecondPage(true);
    }
  };

  return (
    <Layout settings={settings}>
      <Card>
        {openSecondPage ? (
          <Wrapper>{ReactHtmlParser(welcomePageString2)}</Wrapper>
        ) : (
          <Wrapper>{ReactHtmlParser(welcomePageString)}</Wrapper>
        )}
        <Center>
          <Button onClick={handleClick} title={LANG_MAP.NEXT_BUTTON} />
        </Center>
      </Card>
    </Layout>
  );
};

Welcome.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  settings: PropTypes.object,
  welcomePageString: PropTypes.string,
  welcomePageString2: PropTypes.string,
};

export default Welcome;
