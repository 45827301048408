import { en } from "intl/en";
import { es } from "intl/es";
import { br } from "intl/br";
import { it } from "intl/it";

export const getLangMap = (lang) => {
  if (lang === "en") return en;
  if (lang === "es") return es;
  if (lang === "br") return br;
  if (lang === "it") return it;
  return en;
};
