import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

import MenuList from "components/menuList";

const CustomSelect = ({ value, options, onChange, isMulti, placeholder }) => {
  return (
    <Select
      components={{ MenuList }}
      placeholder={placeholder}
      value={value}
      options={options}
      isMulti={isMulti}
      onChange={(value) => (isMulti ? onChange(value) : onChange([value]))}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
        },
      })}
      styles={{
        control: (base) => ({
          ...base,
          fontSize: "14px",
        }),
        option: (base) => ({
          ...base,
          fontSize: "14px",
        }),
      }}
    />
  );
};

CustomSelect.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default CustomSelect;
