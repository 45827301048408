import React, { useState, useImperativeHandle } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import MenuList from "components/menuList";

export const CompanyValuesSelect = ({
  options,
  placeholder,
  componentRef,
  langMap,
}) => {
  const [userAnswers, setUserAnswers] = useState([
    {
      type: langMap.companyValues.PASSION,
      users: [],
    },
    {
      type: langMap.companyValues.INNOVATING,
      users: [],
    },
    {
      type: langMap.companyValues.CONFIDENCE,
      users: [],
    },
    {
      type: langMap.companyValues.WINNING,
      users: [],
    },
    {
      type: langMap.companyValues.DIFFERENCE,
      users: [],
    },
  ]);

  const handleChangeSelect = (values, index) => {
    const updatedSelect = {
      ...userAnswers[index],
      users: values,
    };
    let newState = [...userAnswers];
    newState[index] = updatedSelect;
    setUserAnswers(newState);
  };

  useImperativeHandle(componentRef, () => ({
    getState: () => userAnswers,
  }));

  return (
    <>
      {userAnswers.map((item, idx) => (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          key={idx}
          style={{ marginBottom: "12px" }}
        >
          <Typography style={{ fontWeight: 600 }}>{item.type}</Typography>
          <Grid item xs={6}>
            <Select
              components={{
                MenuList: (props) => (
                  <MenuList
                    {...props}
                    placeholder={langMap.MAX_SELECT_CUSTOM_MESSAGE}
                  />
                ),
              }}
              placeholder={placeholder}
              value={item.user}
              options={userAnswers[idx].users.length === 3 ? [] : options}
              onChange={(values) => handleChangeSelect(values, idx)}
              isMulti
              noOptionsMessage={() => langMap.MAX_SELECT_CUSTOM_MESSAGE}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                },
              })}
              styles={{
                control: (base) => ({
                  ...base,
                  fontSize: "14px",
                }),
                option: (base) => ({
                  ...base,
                  fontSize: "14px",
                }),
              }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

CompanyValuesSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  componentRef: PropTypes.any.isRequired,
  langMap: PropTypes.any.isRequired,
};
