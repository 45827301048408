import React from "react";
import styles from "layout/styles";

const Layout = ({ settings, withoutHeader = false, children }) => {
  return (
    <>
      {!withoutHeader && (
        <styles.Header
          primaryColor={settings?.primaryColor}
          secondaryColor={settings?.secondaryColor}
        >
          <styles.Logo src={settings?.logoBase64} alt="logo" />
        </styles.Header>
      )}

      <styles.Body>{children}</styles.Body>
    </>
  );
};

export default Layout;
