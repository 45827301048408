import React from "react";
import axios from "axios";

import Wizard from "components/wizard";
import Layout from "layout";
import Loader from "components/loader";
import { getLangMap } from "intl/index";

class WizardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: props.formData.questions,
      activeStep: 0,
      isSubmiting: false,
      answers: {},
      apiError: false,
      options: props.formData.options,
    };
  }

  handleNext = (values) => {
    const { activeStep, questions } = this.state;
    this.setState(
      (prevState) => {
        prevState.activeStep = prevState.activeStep + 1;
        prevState.answers[questions[activeStep].id] = values;

        return prevState;
      },
      () => {
        if (activeStep === questions.length - 1) {
          this.submit();
        }
      }
    );
  };

  submit() {
    const { email, participantAccessToken } = this.props;
    const { answers } = this.state;
    const url = new URL(window.location);
    const hash = url.searchParams.get("hash");
    const surveyId = url.searchParams.get("surveyId");
    if (surveyId) {
      // answers must be transformed into an array of objects
      const transformedAnswers = Object.entries(answers)
        .map(([questionId, values]) => {
          return values.map((value) => ({
            survey_id: surveyId,
            from_id: hash,
            to_id: value.value,
            question_id: questionId,
          }));
        })
        .flat();
      axios
        .post(
          `${process.env.REACT_APP_FASTIFY_API_URL}aona-edges`,
          transformedAnswers,
          {
            headers: { Authorization: `Bearer ${participantAccessToken}` },
          }
        )
        .then((res) => {
          console.log(res);
          this.setTimeout(() => {
            this.props.onFinish();
          }, 500);
        })
        .catch((err) => this.props.onFinish());
    } else {
      const formId = url.searchParams.get("formId");
      const accessToken = url.searchParams.get("accessToken") || "";

      this.setState({ isSubmiting: true });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}answer/${email}/${formId}/${hash}`,
          { answers },
          { headers: { "Form-Access-Token": accessToken } }
        )
        .then((res) => {
          this.setTimeout(() => {
            this.props.onFinish();
          }, 500);
        })
        .catch((err) => this.props.onFinish());
    }
  }

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  render() {
    const { activeStep, questions, isSubmiting, options } = this.state;

    if (isSubmiting)
      return (
        <Loader
          title={
            getLangMap(this.props.settings.language).SENDING_DATA ||
            "Sending answer..."
          }
        />
      );

    if (activeStep === questions.length) {
      return null;
    }

    return (
      <Layout settings={this.props.settings}>
        <Wizard
          onNextClick={this.handleNext}
          activeStep={activeStep}
          questions={questions}
          options={options}
          onBackClick={this.handleBack}
          settings={this.props.settings}
        />
      </Layout>
    );
  }
}

export default WizardContainer;
