import styled from "styled-components";

const Header = styled.div`
  text-align: center;
  line-height: 150px;
  font-size: 20px;
  font-weight: bold;
  background: #fff;
  width: 100%;
  height: 100px;
  border-top: 0.4rem solid;
  border-image: ${(props) =>
    `linear-gradient(to right, ${props.secondaryColor} 0%, ${props.primaryColor} 100%)`};

  border-image-slice: 2;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
`;

const Logo = styled.img`
  vertical-align: start;
  max-height: 100px;
`;

export default {
  Header,
  Body,
  Logo,
};
