import React from "react";

import { FixedSizeList as List } from "react-window";

const height = 35;

class MenuList extends React.Component {
  render() {
    const { options, children, maxHeight, getValue, placeholder } = this.props;

    if(options.length === 0) return <div>{placeholder || "No data!"}</div>;

    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

export default MenuList;