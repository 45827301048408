import React from "react";
import Paper from '@material-ui/core/Paper';
import styled from "styled-components";

const CardWrapper = styled(Paper)`
  width: 800px;
  padding: 25px;
  padding-bottom: 40px;
  position: relative;

  @media (max-width: 768px) {
    max-height: 80%;
    overflow: scroll;
  }
`;

const Card = ({ children }) => {
  return <CardWrapper>{children}</CardWrapper>;
};

export default Card;
