import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import MobileStepper from "@material-ui/core/MobileStepper";

const useStyles = makeStyles({
  root: {
    width: "100%",
    background: "transparent",
    justifyContent: "center"
  }
});

const Stepper = ({ steps, activeStep }) => {
  const classes = useStyles();

  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      className={classes.root}
    />
  );
};

export default Stepper;
