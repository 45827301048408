import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const LikertScaleQuestion = ({
  likertChoice,
  likertOptions,
  onChange
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {likertOptions.map(likertOption => (
            <TableCell align={'center'} key={`${likertOption}-headerOption`}>
              {likertOption}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          {likertOptions.map(likertOption => (
            <TableCell align={'center'} key={`bodyOption-${likertOption}`}>
              <Radio
                checked={likertChoice === likertOption}
                onChange={onChange}
                value={likertOption}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

LikertScaleQuestion.propTypes = {
  likertChoice: PropTypes.string.isRequired,
  likertOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LikertScaleQuestion;
