import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const CustomButton = ({ title, onClick, type, isLoading, id, isDisabled }) => {
  return (
    <Button
      variant="contained"
      color={type}
      onClick={onClick}
      id={id}
      disabled={isDisabled}
      style={{ textTransform: 'none'}}
    >
      {isLoading ? <CircularProgress size={20} color="inherit" /> : title}
    </Button>
  );
};

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  isDisabled: PropTypes.bool
};

CustomButton.defaultProps = {
  isLoading: false,
  type: "primary",
  isDisabled: false
};

export default CustomButton;
