import React from "react";
import Layout from "layout";
import Card from "components/card";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
`;

const ContactPage = () => {
  return (
    <Layout withoutHeader={true}>
      <Card>
        <Wrapper>
          <h3>Provided link is not valid!</h3>
          <p>
            Please contact admin: <a href="mailto: admin@cts.com">Send Email</a>
          </p>
        </Wrapper>
      </Card>
    </Layout>
  );
};

export default ContactPage;
