import React, { useState, useEffect } from "react";
import axios from "axios";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import WizardContainer from "containers/Wizard";
import WelcomeContainer from "containers/Welcome";
import UserAlreadyAnswered from "components/userAlreadyAnswered";
import Loader from "components/loader";
import ContactPage from "components/ContactPage";
import { storeLangSettings } from "utils/language";
import jwt from "jsonwebtoken";

const getTheme = ({ primaryColor, secondaryColor }) => {
  return createMuiTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    },
  });
};

const getParams = () => {
  const url = new URL(window.location);
  const hash = url.searchParams.get("hash");
  const formId = url.searchParams.get("formId");
  const email = url.searchParams.get("email");
  const accessToken = url.searchParams.get("accessToken") || "";
  // NEW PARAMS
  const surveyId = url.searchParams.get("surveyId");

  return {
    hash,
    formId,
    email,
    accessToken,
    surveyId,
  };
};

const generateAccessToken = (hash) => {
  const payload = JSON.stringify({ participantId: hash });
  const token = jwt.sign(
    { data: payload },
    process.env.REACT_APP_PLATFORM_JWT_SECRET,
    {
      expiresIn: process.env.REACT_APP_JWT_EXPIRES_IN,
    }
  );
  return token;
};

const App = () => {
  const [isWelcomePage, setWelcomePage] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [userAlreadyAnswered, setUserAlreadyAnswered] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const { hash, formId, email, accessToken, surveyId } = getParams();
  let participantAccessToken = generateAccessToken(hash);

  useEffect(() => {
    setLoading(true);
    // get the updated survey data from the new endpoint
    // set language based on location of the user's IP
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const countryCode = data.country_code.toLowerCase();
        // Map country codes to languages (expand this mapping as needed)
        const languageMap = {
          us: "en",
          gb: "en",
          fr: "fr",
          de: "de",
          es: "es",
          br: "br",
          pt: "br",
          it: "it",
          mx: "es",
          ca: "en",
          au: "en",
          nz: "en",
          ar: "es",
          cl: "es",
          co: "es",
          pe: "es",
          ec: "es",
          uy: "es",
          py: "es",
          bo: "es",
          // Add more country code to language mappings
        };
        const detectedLanguage = languageMap[countryCode] || "en"; // Default to English if not found
        storeLangSettings(detectedLanguage);
      })
      .catch((error) => {
        console.error("Error fetching geolocation:", error);
        storeLangSettings("en");
        setLoading(false);
      });
    if (surveyId) {
      axios
        .get(
          `${process.env.REACT_APP_FASTIFY_API_URL}surveys/${surveyId}/participant/${hash}`,
          {
            headers: { Authorization: `Bearer ${participantAccessToken}` },
          }
        )
        .then((response) => {
          const data = response.data;
          // don't add the current participant to the options (hash is the current participant)
          const transformedData = {
            options: data.participants
              .filter((participant) => participant.id !== hash)
              .map((participant) => ({
                name: participant.name,
                email: participant.email,
                id: participant.id,
              })),
            questions: data.questions.map((question) => ({
              id: question.id,
              title: question.name,
              name: question.content,
              options: [],
              type: "multiSelect",
            })),
            settings: {
              primaryColor: data.survey.settings.primaryColor,
              secondaryColor: data.survey.settings.secondaryColor,
              logoBase64: data.survey.settings.logoBase64,
            },
            welcomePageString: data.survey.settings.welcomePageHtml,
            welcomePageString2: data.survey.settings.privacyPageHtml,
          };

          setFormData(transformedData);
          setLoading(false);
        })
        .catch((error) => {
          const statusCode = error?.response?.status;
          if (statusCode === 409) {
            setUserAlreadyAnswered(true);
            setFormData([]);
            setLoading(false);
            return;
          }
          setFormData(null);
          setLoading(false);
        });
    } else {
      console.log("access token present: ", accessToken);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}form/${email}/${formId}/${hash}`,
          {
            headers: { "Form-Access-Token": accessToken },
          }
        )
        .then(({ data }) => {
          console.log(data);
          setFormData(data);
          storeLangSettings(data.settings.language);
          setLoading(false);
        })
        .catch((error) => {
          const statusCode = error?.response?.status;
          if (statusCode === 409) {
            setUserAlreadyAnswered(true);
            setFormData([]);
            setLoading(false);
            return;
          }
          if (statusCode === 401) {
            const redirectUrl = error?.response?.data?.redirectUrl;
            window.location.href = redirectUrl;
            setFormData([]);
            setLoading(false);
            return;
          }
          // TODO some notification system!!!
          setFormData(null);
          setLoading(false);
        });
    }
  }, [
    accessToken,
    email,
    formId,
    hash,
    surveyId,
    reloadPage,
    participantAccessToken,
  ]);

  if (isLoading) return <Loader />;

  if (!formData) return <ContactPage />;

  if (userAlreadyAnswered) {
    return <UserAlreadyAnswered />;
  }

  if (isWelcomePage)
    return (
      <ThemeProvider theme={getTheme(formData?.settings)}>
        <WelcomeContainer
          onNextClick={() => setWelcomePage(false)}
          welcomePageString={formData?.welcomePageString}
          welcomePageString2={formData?.welcomePageString2}
          settings={formData?.settings}
        />
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={getTheme(formData.settings)}>
      <WizardContainer
        formData={formData}
        email={email}
        settings={formData?.settings}
        onFinish={() => setReloadPage(!reloadPage)}
        participantAccessToken={participantAccessToken}
      />
    </ThemeProvider>
  );
};

export default App;
