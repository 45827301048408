import React from "react";
import PropTypes from "prop-types";
import { StyledInner, StyledLoader, StyledWrapper, StyledText } from "components/loader/styles";

const Loader = ({ title }) => {
  return (
    <StyledLoader>
      <StyledWrapper>
        <StyledInner />
        <StyledText>
          {title}
        </StyledText>
      </StyledWrapper>
    </StyledLoader>
  );
};

Loader.propTypes = {
  title: PropTypes.string,
};

Loader.defaultProps = {
  title: "LOADING...",
};

export default Loader;
